html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
}

html {
    overflow: hidden;
}

/* Body is set to hidden when a dropdown is open */
body {
    overflow: visible !important;
}

a {
    color: #006a9d;
    text-decoration: none;
}
a:hover{ 
    background-color: #E0E0E0;
    text-decoration: underline;
}

.insborder{
    border: 1px solid darkgrey;
    padding: 10px;
}

.Mui-error {
    color: #be0000 !important;
}

.Mui-focusVisible {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(153,0,51,.50) !important;
            box-shadow: 0 0 0 0.2rem rgba(153,0,51,.50) !important;
    opacity: .9
}

/* Expand widget to take up entire right screen */
.leaflet-top.leaflet-right {
    bottom: 0px;
}
.leaflet-control-layers-expanded {
    border-radius: 0px !important;
    position: absolute !important;
    margin: 0px !important;
    padding: 0px !important;
    top: 0px !important;
    bottom: 0px !important;
    right: 0px !important;
    width: 227px !important;
    overflow: auto !important;
    background-color: rgba(100, 100, 100, 0.5) !important;
}
/* Layer list background, text */
.leaflet-control-layers-list {
    background-color: #ebe7da;
    text-shadow: none;
}
.leaflet-control-layers-list label {
    font-size: 11px;
    margin: 0px;
}
.leaflet-control-layers-overlays label {
    font-weight: bold;
}
.leaflet-control-layers-overlays span.expandable {
    cursor: pointer;
}
/* Collapsible sections */
.legendlayers-header {
    margin: 0px;
    padding: 3px;
    border-top: 1px solid #bba;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(232, 228, 216, 1)), to(rgba(125, 120, 107, 1)));
    background: -webkit-linear-gradient(top, rgba(232, 228, 216, 1) 0%, rgba(125, 120, 107, 1) 100%);
    background: -o-linear-gradient(top, rgba(232, 228, 216, 1) 0%, rgba(125, 120, 107, 1) 100%);
    background: linear-gradient(to bottom, rgba(232, 228, 216, 1) 0%, rgba(125, 120, 107, 1) 100%);
    font-size: 11px;
    cursor: pointer;
}
.legendlayers-section {
    padding: 7px;
}
/* Top header & minimize button */
.legendlayers-top-header {
    border: 1px solid black;
    margin: 0px;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(150, 150, 150, 0.2)), to(rgba(200, 200, 200, 0.3)));
    background: -webkit-linear-gradient(top, rgba(150, 150, 150, 0.2) 0%, rgba(200, 200, 200, 0.3) 100%);
    background: -o-linear-gradient(top, rgba(150, 150, 150, 0.2) 0%, rgba(200, 200, 200, 0.3) 100%);
    background: linear-gradient(to bottom, rgba(150, 150, 150, 0.2) 0%, rgba(200, 200, 200, 0.3) 100%);
    color: black;
    text-shadow: 1px 1px 3px #ddd;
}
.legendlayers-top-header,
.legendlayers-header,
.leaflet-control-layers-base,
.leaflet-control-layers-overlays,
.legendlayers-section.leaflet-control-layers-list,
ul.legend,
.leaflet-control-layers-expanded .legendlayers-section.leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-base,
.leaflet-control-layers-expanded .leaflet-control-layers-overlays,
.leaflet-control-layers-expanded .legendlayers-section.leaflet-control-layers-list,
.leaflet-control-layers-expanded ul.legend {
    display: none;
}
.leaflet-control-layers-expanded .legendlayers-top-header,
.leaflet-control-layers-expanded .legendlayers-header,
.leaflet-control-layers-expanded .leaflet-control-layers-base.expanded,
.leaflet-control-layers-expanded .leaflet-control-layers-overlays.expanded,
.leaflet-control-layers-expanded .legendlayers-section.leaflet-control-layers-list.expanded,
.leaflet-control-layers-expanded ul.legend.expanded {
    display: inherit;
}
.legendlayers-top-header a {
    float: left;
    width: 13px;
    height: 13px;
    margin: 2px;
    border: 1px solid #808080;
    border-radius: 3px;
    background-color: #ccc;
    text-align: center;
    line-height: 13px;
    text-decoration: none;
    color: black;
}
/* Legend color patches & labels */
ul.legend {
    list-style-type: none;
    margin: 0px;
    padding-left: 1em;
}
li.legend span.legend-color {
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-right: 1em;
}
li.legend img.legend-image {
    margin-right: 1em;
}
li.legend span.legend-title {
    font-weight: normal;
}

/* MARKER CLUSTERS */
.marker-cluster-red {
    background-color: rgba(207, 56, 45, 0.3);
}
.marker-cluster-red div {
    background-color: rgba(207, 56, 45, 0.6);
}
.marker-cluster-orange {
    background-color: rgba(239, 141, 47, 0.3);
}
.marker-cluster-orange div {
    background-color: rgba(239, 141, 47, 0.6);
}
.marker-cluster-green {
    background-color: rgba(109, 164, 46, 0.3);
}
.marker-cluster-green div {
    background-color: rgba(109, 164, 46, 0.6);
}
.marker-cluster-teal {
    background-color: rgba(49, 162, 204, 0.3);
}
.marker-cluster-teal div {
    background-color: rgba(49, 162, 204, 0.6);
}
.marker-cluster-purple {
    background-color: rgba(194, 78, 177, 0.3);
}
.marker-cluster-purple div {
    background-color: rgba(194, 78, 177, 0.6);
}
.marker-cluster-darkred {
    background-color: rgba(150, 46, 52, 0.3);
}
.marker-cluster-darkred div {
    background-color: rgba(150, 46, 52, 0.6);
}
.marker-cluster-darkblue {
    background-color: rgba(9, 91, 151, 0.3);
}
.marker-cluster-darkblue div {
    background-color: rgba(9, 91, 151, 0.6);
}
.marker-cluster-lime {
    background-color: rgba(113, 120, 37, 0.3);
}
.marker-cluster-lime div {
    background-color: rgba(113, 120, 37, 0.6);
}
.marker-cluster-darkpurple {
    background-color: rgba(89, 52, 102, 0.3);
}
.marker-cluster-darkpurple div {
    background-color: rgba(89, 52, 102, 0.6);
}
.marker-cluster-royalblue {
    background-color: rgba(64, 100, 112, 0.3);
}
.marker-cluster-royalblue div {
    background-color: rgba(64, 100, 112, 0.6);
}
.marker-cluster-peach {
    background-color: rgba(254, 136, 120, 0.3);
}
.marker-cluster-peach div {
    background-color: rgba(254, 136, 120, 0.6);
}
.marker-cluster-yellow {
    background-color: rgba(248, 202, 133, 0.3);
}
.marker-cluster-yellow div {
    background-color: rgba(248, 202, 133, 0.6);
}
.marker-cluster-lightgreen {
    background-color: rgba(183, 242, 114, 0.3);
}
.marker-cluster-lightgreen div {
    background-color: rgba(183, 242, 114, 0.6);
}
.marker-cluster-skyblue {
    background-color: rgba(140, 213, 255, 0.3);
}
.marker-cluster-skyblue div {
    background-color: rgba(140, 213, 255, 0.6);
}
.marker-cluster-pink {
    background-color: rgba(252, 140, 230, 0.3);
}
.marker-cluster-pink div {
    background-color: rgba(252, 140, 230, 0.6);
}
.marker-cluster-lightred {
    background-color: rgba(229, 124, 134, 0.3);
}
.marker-cluster-lightred div {
    background-color: rgba(229, 124, 134, 0.6);
}
.marker-cluster-gray {
    background-color: rgba(164, 162, 165, 0.3);
}
.marker-cluster-gray div {
    background-color: rgba(164, 162, 165, 0.6);
}
.marker-cluster-black {
    background-color: rgba(47, 45, 48, 0.3);
}
.marker-cluster-black div {
    background-color: rgba(47, 45, 48, 0.6);
}

/*address search css input */
.leaflet-touch .geocoder-control-input {
    width: 117%;
    position: absolute !important;
}
/* dropdown input window */
.leaflet-touch .geocoder-control-suggestions {
    position: absolute !important;
    width: 317px !important;
}

.geocoder-control-expanded {
    position: absolute !important;
    width: 310px;
    left: 400px;
}

.geocoder-control-input {
    background-image: url("https://raw.githubusercontent.com/Esri/esri-leaflet-geocoder/master/src/img/search.png");
}

